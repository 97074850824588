import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    headerContainer: {
        backgroundColor: Colors.white,
        //padding: 10,
        elevation: 3,
    },
    searchBarContainer: {
        paddingTop: 0,
        padding: 10,
    },
    selectionList: {
        backgroundColor: Colors.white,
        // minHeight: '100%',
        // flex:1
    },
    rowItemWrapper: {
        marginHorizontal: 5,
    },

    creationButtonContainer: {
        //alignItems: 'center',
        marginTop: 15,
        marginBottom: 50,
        marginHorizontal: 15,
    },
    creationButtonHelperText: {
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 15,
    },

    tabBar: {
        marginTop: 5,
        borderTopWidth: 1,
        borderColor: Colors.light_gray,
    },
    tabItem: {
        marginHorizontal: 10,
        paddingVertical: 10,
        paddingHorizontal: 15,
    },
    activeTabItem: {
        borderTopWidth: 3,
        borderColor: Colors.primary,
        fontFamily: 'eina01-semibold',
    },
});
