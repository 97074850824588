import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { PostService } from 'gc-common';
import React, { RefObject, useEffect, useState } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { GCButton } from '../../components/Button/GCButton';
import { Ratings } from '../../components/Ratings/Ratings';
import { SelectCard } from '../../components/SelectCard/SelectCard';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { styles } from './styles';

export const useReviewCreation = (
    dish_id?: string,
    oldRating?: number,
    oldContent?: string,
    scrollRef?: RefObject<KeyboardAwareScrollView>
) => {
    const [rating, setRating] = useState(oldRating || 0);
    const [content, setContent] = useState<string>(oldContent || '');

    const [recommendedKeywords, setRecommendedKeywords] = useState<string[]>([]);

    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
    const [customKeywords, setCustomKeywords] = useState<string[]>([]);

    const [loadingKeywords, setLoadingKeywords] = useState(false);
    const [loadingContent, setLoadingContent] = useState(false);

    const generateReview = async () => {
        if (!dish_id) return;
        setLoadingContent(true);
        const rsp = await PostService.generatePostReview(dish_id, rating, [
            ...selectedKeywords,
            ...customKeywords,
        ]);
        setLoadingContent(false);
        setContent(rsp.data);
    };

    const toggleKeywordSelection = (w: string, selected: boolean) => {
        setSelectedKeywords((keywords) => {
            const newArray = keywords.filter((k) => k !== w);
            if (selected) newArray.push(w);
            return newArray;
        });
    };

    const onTypeCustomKeywords = (s: string) => {
        const keywords = s
            .split(',')
            .filter((s) => !!s)
            .map((s) => s.trim());
        setCustomKeywords(keywords);
    };

    const onRatingChange = async (rating: number) => {
        console.log('on change');
        if (!dish_id) return;
        setLoadingKeywords(true);
        scrollRef?.current?.scrollToEnd();
        const rsp = await PostService.generatePostKeywords(dish_id, rating);

        if (rsp.errorMessage) return;

        setLoadingKeywords(false);
        setRecommendedKeywords(rsp.data);
        scrollRef?.current?.scrollToEnd();
    };

    useEffect(() => {
        console.log('rating change');
        if (rating > 0) {
            onRatingChange(rating);
        }
    }, [rating]);

    const renderAIReview = (expanded = false) => {
        return (
            <View style={CommonStyles.section}>
                <View style={CommonStyles.titleRow}>
                    <FontAwesome5 name={'edit'} style={CommonStyles.titleIcon} />
                    <Text style={CommonStyles.title}>Review</Text>
                </View>
                {!!dish_id && expanded && (
                    <View style={CommonStyles.sectionContent}>
                        <View style={[styles.ratingWidget, style.widget]}>
                            <View>
                                <Text style={style.widgetTitle}>Select a rating first:</Text>
                                <Ratings
                                    rating={rating}
                                    editable={true}
                                    onRatingPressed={(n) => setRating(n)}
                                />
                            </View>
                        </View>

                        {rating > 0 && (
                            <View style={style.widget}>
                                <Text style={style.widgetTitle}>Select keywords:</Text>
                                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {!loadingKeywords &&
                                        recommendedKeywords.map((keyword, index) => {
                                            return (
                                                <SelectCard
                                                    text={keyword}
                                                    size={'small'}
                                                    key={index}
                                                    onChange={(isSelected) =>
                                                        toggleKeywordSelection(keyword, isSelected)
                                                    }
                                                />
                                            );
                                        })}
                                    {loadingKeywords && (
                                        <SkeletonPlaceholder>
                                            <View style={CommonStyles.flexRowCenter}>
                                                <SkeletonPlaceholder.Item
                                                    width={50}
                                                    height={20}
                                                    borderRadius={15}
                                                    marginHorizontal={2}
                                                />
                                                <SkeletonPlaceholder.Item
                                                    width={50}
                                                    height={20}
                                                    borderRadius={15}
                                                    marginHorizontal={2}
                                                />
                                                <SkeletonPlaceholder.Item
                                                    width={50}
                                                    height={20}
                                                    borderRadius={15}
                                                    marginHorizontal={2}
                                                />
                                            </View>
                                        </SkeletonPlaceholder>
                                    )}
                                </View>
                                <Text style={style.widgetTitle}>
                                    Add your own keywords (optional):
                                </Text>

                                <TextInput
                                    // multiline={true}
                                    placeholderTextColor={Colors.light_gray}
                                    style={style.keywordInput}
                                    placeholder={'Type here, separated by comma "," '}
                                    onChangeText={onTypeCustomKeywords}
                                />
                            </View>
                        )}

                        {rating > 0 && (
                            <View style={style.widget}>
                                <View style={[style.enterReviewTitle, style.widgetTitle]}>
                                    <Text style={style.widgetTitle}>Enter your review or use </Text>
                                    <GCButton size={'tiny'} onClick={generateReview}>
                                        <View style={style.useAiButton}>
                                            <MaterialCommunityIcons
                                                name={'robot-outline'}
                                                color={Colors.white}
                                            />
                                            <Text style={{ color: Colors.white }}>
                                                {' '}
                                                AI Assistant
                                            </Text>
                                        </View>
                                    </GCButton>
                                </View>

                                {!loadingContent && (
                                    <TextInput
                                        multiline={true}
                                        placeholderTextColor={Colors.light_gray}
                                        placeholder={
                                            'You can start typing your review here or use AI Assistant to generate a review with keywords: ' +
                                            [...selectedKeywords, ...customKeywords].join(',')
                                        }
                                        style={style.contentInput}
                                        value={content}
                                        onChangeText={(t) => setContent(t.trim())}
                                        textAlignVertical={'top'}
                                    />
                                )}
                                {loadingContent && (
                                    <SkeletonPlaceholder>
                                        <View style={CommonStyles.flexRowCenter}>
                                            <SkeletonPlaceholder.Item
                                                width={'100%'}
                                                height={120}
                                                borderRadius={15}
                                            />
                                        </View>
                                    </SkeletonPlaceholder>
                                )}
                            </View>
                        )}
                    </View>
                )}
            </View>
        );
    };

    return {
        render: renderAIReview,
        rating,
        content,
    };
};

const style = StyleSheet.create({
    widget: {
        marginBottom: 15,
        // backgroundColor: Colors.primary,
    },
    widgetTitle: {
        color: Colors.gray,
        marginBottom: 5,
    },
    keywordInput: {
        // borderBottomWidth: 1,
        // borderBottomColor: Colors.gray,
        // height: 50,
        // marginBottom: 20,
        backgroundColor: Colors.light_gray_2,
        padding: 15,
        borderRadius: 15,
    },

    contentInput: {
        height: 'auto',
        backgroundColor: Colors.light_gray_2,
        padding: 15,
        borderRadius: 15,
        minHeight: 150,
        alignItems: 'flex-start',
    },

    useAiButton: {
        color: Colors.white,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    enterReviewTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
});
