import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    bottomSpacer: {
        height: 100,
    },
    photoBox: {
        borderRadius: 9,
        width: '33.33%',
        aspectRatio: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 5,
        paddingVertical: 5,
        overflow: 'hidden',
    },
    photo: {
        width: '100%',
        height: '100%',
        borderRadius: 9,
    },
    addPhoto: {
        width: '100%',
        height: '100%',
        borderRadius: 9,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: Colors.gray,
    },
    photoContainer: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    ratingWidget: {
        marginVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },

    // Selection Page
    headerContainer: {
        backgroundColor: Colors.white,
        //padding: 10,
        elevation: 3,
    },
    searchBarContainer: {
        backgroundColor: Colors.white,
        paddingTop: 0,
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectionList: {
        backgroundColor: Colors.white,
        minHeight: '100%',
    },
    rowItemWrapper: {
        marginHorizontal: 5,
    },

    creationButtonContainer: {
        //alignItems: 'center',
        marginTop: 15,
        marginBottom: 50,
        marginHorizontal: 15,
    },
    creationButtonHelperText: {
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 15,
    },

    dataConfirmationText: {
        marginTop: 10,
        fontFamily: 'eina01-semibold',
    },
});
