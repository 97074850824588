import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Dish, Restaurant, RestaurantService } from 'gc-common';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { DeviceEventEmitter, FlatList, ListRenderItem, ScrollView, Text, View } from 'react-native';
import { useDebounce } from 'use-debounce';
import { useAuth } from '../../context/authContext';
import { RootStackParamList } from '../../types';
import { DEFAULT_DISH_IMAGE } from '../../types/Constants';
import { GCButton } from '../Button/GCButton';
import CustomInputText from '../CustomInputText/CustomInputText';
import { GotoHomeWidget } from '../GotoHomeWidget';
import { RowItem } from '../RowItem/RowItem';
import { styles } from './styles';
import {EventLogger} from "../../utils/EventLogger";

export interface MenuProps {
    restaurantId: string;
    onSelection?: (dish: Dish, isNew?: boolean) => void;
    noImageUploadForCreation?: boolean;
}
export interface MenuRef {
    refresh(): void;
}
export const Menu = React.forwardRef<MenuRef, MenuProps>(
    ({ onSelection, restaurantId, noImageUploadForCreation }, ref) => {
        const [dataList, setDataList] = useState<Dish[]>([]);
        const [categories, setCategories] = useState<string[]>([]);
        const [activeCategories, setActiveCategories] = useState<string>();
        const [searchKeyword, setSearchKeyword] = useState('');
        const [searchKeywordDebounced] = useDebounce(searchKeyword, 300);
        const [restaurant, setRestaurant] = useState<Restaurant>();

        const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
        const auth = useAuth();

        useImperativeHandle(ref, () => ({
            refresh: () => {
                fetchData(restaurantId);
            },
        }));

        useEffect(() => {
            EventLogger.viewMenu(restaurantId);
            fetchData(restaurantId);
        }, []);

        useEffect(() => {
            DeviceEventEmitter.addListener('dish-created', (dish: Dish) => {
                onSelection?.(dish, true);
            });

            return () => {
                DeviceEventEmitter.removeAllListeners('dish-created');
            };
        }, []);

        const fetchData = async (id: string) => {
            const [data, _restaurant] = await Promise.all([
                RestaurantService.getAllActiveDish(id),
                RestaurantService.get(id),
            ]);
            setDataList(data.data);
            setRestaurant(_restaurant.data);

            const _cats =
                _restaurant.data?.menu_category_order
                    ?.split(/,|;|，|；/)
                    .filter((i) => i)
                    .map((i) => i.trim()) || [];
            const _catsFromDishes = data.data.map((dish) => dish.categories).flat();
            [...new Set(_catsFromDishes)].forEach((cat) => {
                if (!_cats.includes(cat)) _cats.push(cat);
            });
            // const _cats = data.data.map((dish) => dish.categories).flat();
            console.log('Got categories:', _cats);
            setCategories(_cats);
        };

        const renderHeaderBar = () => {
            return (
                <View style={styles.headerContainer}>
                    <View style={styles.searchBarContainer}>
                        <CustomInputText
                            isPassword={false}
                            leftIcon={'search'}
                            placeholder={'Search Dish or create a new one.'}
                            onChangeText={(t) => setSearchKeyword(t)}
                        />
                    </View>
                    <View>
                        <ScrollView
                            horizontal={true}
                            style={styles.tabBar}
                            showsHorizontalScrollIndicator={false}
                        >
                            <Text
                                style={[styles.tabItem, !activeCategories && styles.activeTabItem]}
                                onPress={() => {
                                    EventLogger.clickMenuCategory(restaurantId, 'ALL');
                                    setActiveCategories(undefined)
                                }}
                            >
                                All
                            </Text>
                            {categories.map((i, index) => (
                                <Text
                                    key={index}
                                    style={[
                                        styles.tabItem,
                                        activeCategories === i && styles.activeTabItem,
                                    ]}
                                    onPress={() => {
                                        EventLogger.clickMenuCategory(restaurantId, i);
                                        setActiveCategories(i)
                                    }}
                                >
                                    {i}
                                </Text>
                            ))}
                        </ScrollView>
                    </View>
                    <GotoHomeWidget />
                </View>
            );
        };
        const renderList: ListRenderItem<Dish> = ({ item }) => {
            return (
                <View style={styles.rowItemWrapper}>
                    <RowItem
                        image={item.image_urls?.[0] || DEFAULT_DISH_IMAGE}
                        title={item.name}
                        rating={item.rating}
                        subTitle={item.description}
                        price={item.price == 0 ? '' : `$${item.price}`}
                        onPress={() => {
                            onSelection?.(item);
                        }}
                    />
                </View>
            );
        };

        const renderFooter = () => {
            return (
                <View style={styles.creationButtonContainer}>
                    <Text style={styles.creationButtonHelperText}>
                        Can't find what you are looking for?
                    </Text>
                    <GCButton
                        text={'Create a new one'}
                        onClick={() => {
                            if (auth.isLoggedIn)
                                navigation.push('DishCreation', {
                                    restaurantId,
                                    categories,
                                    noUpload: noImageUploadForCreation,
                                });
                            else navigation.navigate('SignIn' as any);
                        }}
                    />
                </View>
            );
        };

        return (
            <View style={{flex:1}}>
                <FlatList
                    data={dataList
                        .filter((dish) => {
                            if (!searchKeyword) return true;
                            return new RegExp(searchKeyword, 'i').test(dish.name);
                        })
                        .filter((dish) =>
                            activeCategories ? dish.categories.includes(activeCategories) : true
                        )}
                    style={styles.selectionList}
                    renderItem={renderList}
                    ListHeaderComponent={renderHeaderBar()}
                    stickyHeaderIndices={[0]}
                    ListFooterComponent={renderFooter}
                />
            </View>
        );
    }
);
