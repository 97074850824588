import { Dish } from 'gc-common';
import React from 'react';
import { DeviceEventEmitter } from 'react-native';
import { Menu } from '../../components/Menu/Menu';
import { RootStackScreenProps } from '../../types';

export const DishSelectPage = ({ route, navigation }: RootStackScreenProps<'DishSelect'>) => {
    const restaurantId = route.params.restaurantId;

    const onSelection = (value: Dish, isNew?: boolean) => {
        DeviceEventEmitter.emit('dish-selected', value);
        navigation.pop(isNew ? 2 : 1);
    };

    return <Menu onSelection={onSelection} restaurantId={restaurantId} noImageUploadForCreation />;
};
